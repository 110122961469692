import React from "react";
import { Helmet } from "react-helmet";

function NotFound() {
  return (
    <>
      <Helmet>
        <title>Page Not Found</title>
      </Helmet>
      <h1 className="text-6xl font-bold text-center mt-6">Not Found</h1>
    </>
  );
}

export default NotFound;
