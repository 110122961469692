import React, { useMemo, useState } from "react";

export interface UserContextObject {
  loggedIn: boolean;
}

export interface UserContextWrapper {
  userContext: UserContextObject;
  setUserContext: (userContext: UserContextObject) => void;
}

export const UserContext = React.createContext<UserContextWrapper>({
  userContext: { loggedIn: false },
  setUserContext: () => {},
});

function SiteContexts(props: { children: React.ReactNode }) {
  const [userContextState, setUserContextState] = useState<UserContextObject>({
    loggedIn: false,
  });
  const userContextValue = useMemo<UserContextWrapper>(
    () => ({
      userContext: userContextState,
      setUserContext: setUserContextState,
    }),
    [userContextState]
  );

  return (
    <UserContext.Provider value={userContextValue}>
      {props.children}
    </UserContext.Provider>
  );
}

export default SiteContexts;
