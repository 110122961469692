import React from "react";
import { Helmet } from "react-helmet";

function Home() {
  return (
    <>
      <Helmet>
        <title>Project 26</title>
      </Helmet>
      <h1 className="text-6xl font-bold text-center mt-6">
        Project{" "}
        <a className="text-blue-600" href="#123">
          26
        </a>
      </h1>
    </>
  );
}

export default Home;
