import React from "react";
import { Outlet } from "react-router-dom";

type LayoutProps = {};
type LayoutState = {};

class Layout extends React.Component<LayoutProps, LayoutState> {
  render() {
    return (
      <div className="flex min-h-screen flex-col items-center justify-center py-0">
        <main className="max-w-6xl mx-auto grow p-2  w-full">
          <Outlet />
        </main>
      </div>
    );
  }
}

export default Layout;
